import { useStaticQuery, graphql } from "gatsby";

export const useTwinWorkAppQuery = (component = "app") => {
  const { allTwinPage } = useStaticQuery(
    graphql`
      query TWIN_WORK_APP_QUERY {
        allTwinPage(filter: { page: { eq: "webinar" } }) {
          edges {
            node {
              page
              component
              data {
                planPrice
                offerPrice
              }
            }
          }
        }
      }
    `
  );
  // console.log("all node", allTwinPage.edges);
  const findComponent = allTwinPage.edges.find(
    item => item.node.component === component
  );
  // console.log("findComponent", findComponent);
  let node = [];
  if (findComponent) {
    node = findComponent.node.data;
  } else {
    node = [
      {
        planPrice: "0",
        offerPrice: "0",
      },
    ];
  }

  // console.log(node);

  return node;
};
